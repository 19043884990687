import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./RootApp";

import HorizontalLayout from "@src/layouts/HorizontalLayout";
import VerticalLayout from "@src/layouts/VerticalLayout";
import BlankLayout from "@layouts/BlankLayout";
import { useLayout } from "@hooks/useLayout";
import { useUser } from "@hooks/useUser";
import { getRoutes } from "./router/routes";
import themeConfig from "@configs/themeConfig";
import { ThemeContext } from "./utility/context/ThemeColors";
import api from "./utility/api";
import Spinner from "./@core/components/spinner/Fallback-spinner";
import { PermissionButton, PermissibleSection } from './components'
export { useLayout, getRoutes, HorizontalLayout, VerticalLayout, useUser, themeConfig, ThemeContext, Spinner, api, PermissionButton, PermissibleSection };

export const layoutConfig = {
  "skin": "dark",
  "isRTL": false,
  "layout": "horizontal",
  "lastLayout": "horizontal",
  "menuCollapsed": false,
  "footerType": "sticky",
  "navbarType": "floating",
  "menuHidden": false,
  "contentWidth": "full",
  "navbarColor": "white"
}

export const getLayout = (reactDom) => {
  return {
    blank: <BlankLayout />,
    horizontal: <HorizontalLayout reactDom={reactDom} />,
    vertical: <VerticalLayout reactDom={reactDom} />,
  }
  // vertical: <VerticalLayout />,
};

export const TestComponent = ({ Link }) => {
  return (
    <Link to="/"> Test link</Link>
  )
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;

// export const LayoutApplication=({children,...props})=>{
// console.log("LayoutApplication")
//   return <Root {...props}>
//     {children}
//   </Root>
// }
