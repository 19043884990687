// ** Store Imports
// import { handleFooterType } from "@store/layout";
// import { useDispatch, useSelector } from "react-redux";

const handleFooterType=(b)=>{
  console.log(b)
}
export const useFooterType = (store) => {
  // // ** Hooks
  // const dispatch = useDispatch();
  // const store = useSelector((state) => state.layout);

  const setFooterType = (type) => {
    dispatch(handleFooterType(type));
    handleFooterType(type);
  };

  return { setFooterType, footerType: store.footerType };
};
