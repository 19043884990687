import axios from "axios";
import { useUser } from "../hooks/useUser";


axios.interceptors.request.use(async function (config) {
    // debugger
    const {getToken} = useUser();
    const token =await getToken();
    if(!token )
    {
        // window.location.href = "/login";
        // return;
    }
    config.headers.Authorization = token ? `Bearer ${token}` : "";

    return config;

});

axios.interceptors.response.use(null, (error) => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;


    if (!expectedError) {
        console.log("Logging unexpected error :::", error);
        // toast.error("un expected error occrured", toastProbs);
    }
    return Promise.reject(error);
});

export default axios;
