import React from "react";
import { Button } from "reactstrap";
import { useUser } from "../utility/hooks/useUser";

function PermissionButton({ permission, ...props }) {
  // const { user } = useSelector((state) => state.auth);
  const { hasPermission } = useUser();

  if (!permission)
    return (
      <Button.Ripple {...props}>
        {props.children}
      </Button.Ripple>
    )
  return hasPermission(permission) ? (
    <Button.Ripple {...props}>
      {props.children}
    </Button.Ripple>
  ) : null;
}

export default PermissionButton;
