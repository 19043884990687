
import Layout from "@layouts/VerticalLayout";

const VerticalLayout = ({navigation=[],...props}) => {
 const {reactDom}=props
  const {Outlet}=reactDom

  return (
    <Layout menuData={navigation} {...props}>
      <Outlet />
    </Layout>
  );
};

export default VerticalLayout;
