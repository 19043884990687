import React from "react";
import { Button } from "reactstrap";
import { useUser } from "../utility/hooks/useUser";

function PermissibleSection({ permission, ...props }) {

  // const { user } = useSelector((state) => state.auth);
  const { hasPermission } = useUser();

  if (!permission)
    return (
      <>
        {props.children}
      </>
    )
  return hasPermission(permission) ? (
    <>
      {props.children}
    </>
  ) : null;
}

export default PermissibleSection;
