import { Mail, Home } from "react-feather";

export const navigation=[{
    id: "home",
    title: "Home",
    icon: <Home size={20} />,
    navLink: "/",
  },
  {
    id: "templateapplication",
    title: "Test Application1",
    icon: <Mail size={20} />,
    navLink: "/test",
  },
  {
    id: "templateapplication",
    title: "Template Application",
    icon: <Mail size={20} />,
    navLink: "/temp",
  }
  ];