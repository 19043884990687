// ** React Imports
import React from "react";
import { Fragment, lazy } from "react";
// import { Navigate } from "react-router-dom";
// ** Layouts
import BlankLayout from "@layouts/BlankLayout";
import VerticalLayout from "@src/layouts/VerticalLayout";
import HorizontalLayout from "@src/layouts/HorizontalLayout";
import LayoutWrapper, { CustomeWrapper } from "@src/@core/layouts/components/layout-wrapper";
import { useLayout } from "@hooks/useLayout";
import themeConfig from "@configs/themeConfig";
// ** Route Components
import PublicRoute from "@components/routes/PublicRoute";

// ** Utils
import { isObjEmpty } from "@utils";

const getAllLayout = (reactDom, appTheme) => {
  return {
    blank: <BlankLayout />,
    horizontal: <HorizontalLayout reactDom={reactDom} navigation={appTheme?.navigations??[]} appTheme={appTheme} />,
    vertical: <VerticalLayout reactDom={reactDom} navigation={appTheme?.navigations??[]} appTheme={appTheme} />,
  }
};

// ** Document title
const TemplateTitle = "%s - Vuexy React Admin Template";

// ** Default Route
const DefaultRoute = "/test/home";

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta };
    } else {
      return {};
    }
  }
};

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout, routes, reactDom, DefaultlLayout) => {
  const LayoutRoutes = [];
  if (routes) {
    routes.forEach((route,i) => {
      route.key=i;
      let isBlank = false;
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        const RouteTag = PublicRoute;

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false);
        }
        if (route.element) {
          const isEmpty = isObjEmpty(route.element.props);
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isEmpty && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
              LayoutWrapper
              : Fragment;
          route.element = <Wrapper key={i} {...(isBlank === false ? getRouteMeta(route) : {})}>
            <RouteTag route={route}
              reactDom={reactDom}>{route.element}</RouteTag>
          </Wrapper>

        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route);
      }
      return LayoutRoutes;
    });
  }
  return LayoutRoutes;
};

const defualtTheme = {
  "skin": "light",
  "isRTL": false,
  "layout": "horizontal",
  "lastLayout": "horizontal",
  "menuCollapsed": false,
  "footerType": "sticky",
  "navbarType": "hidden",
  "menuHidden": false,
  "contentWidth": "full",
  "navbarColor": "white"
}

const getRoutes = (layout, routes, reactDom, appConfitTheme) => {
  const appTheme = { ...defualtTheme, ...appConfitTheme }
  const defaultLayout = layout || "horizontal";
  const layouts = ["vertical", "horizontal", "blank"];
  const AllRoutes = [];
  const getLayout = getAllLayout(reactDom, appTheme);
  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout, routes, reactDom, defaultLayout);

    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes,
    });
  });
  return AllRoutes;
};
export { DefaultRoute, TemplateTitle, getRoutes };
