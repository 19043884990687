import React, { useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
    auth: {
        clientId: 'f8970b73-4e1a-4c94-84b3-49561df99eec', // Replace with your Azure AD client ID
        // clientId: 'ae03126e-e674-4bc6-8827-bb2a2b8a06bf', // Replace with your Azure AD client ID
        // authority: 'https://login.microsoftonline.com/1cec92f6-5859-4f6a-b76e-9f14529924a2', // Replace with your Azure AD authority URL (e.g., 'https://login.microsoftonline.com/your-tenant-id')
        authority: 'https://login.microsoftonline.com/1cec92f6-5859-4f6a-b76e-9f14529924a2', // Replace with your Azure AD authority URL (e.g., 'https://login.microsoftonline.com/your-tenant-id')
        redirectUri: window.location.origin, // The URL where Azure AD will redirect after authentication
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

const accessTokenRequest = (user) => ({
    // scopes: ['openid', 'profile', 'email','user.read'],
    // scopes: ["User.Read","api://f8970b73-4e1a-4c94-84b3-49561df99eec"],
    scopes: [
        // "api://f8970b73-4e1a-4c94-84b3-49561df99eec",
        "api://f8970b73-4e1a-4c94-84b3-49561df99eec/Refund.Admins",
        "api://f8970b73-4e1a-4c94-84b3-49561df99eec/Refund.Readers",
        // "api://f8970b73-4e1a-4c94-84b3-49561df99eec/.default"
    ],
    account: user,
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
});
export const useUser = () => {
    // debugger
    // const { instance, inProgress, accounts } = useMsal();
    const msalInstance = new PublicClientApplication(msalConfig);
    let user = msalInstance.getAllAccounts()[0];
    // useEffect(async () => {

    //     let result = await getToken();
    //     console.log(result)
    // }, [])

    const getToken = async () => {
        let response = await msalInstance.acquireTokenSilent(accessTokenRequest(user))
            .catch((err) => {
                console.error(err)
                return undefined;
            });

        return response.accessToken;
    }

    const canAccessApplication = (appName) => {
        return !!user.idTokenClaims?.roles?.map(r=>r.toLowerCase()).some(r => r.startsWith(appName.toLowerCase())) || false;
    }

    const hasPermission = (permissions) => {
        let permissionsArr = permissions.toLowerCase().split(",");
        for (const role of user.idTokenClaims?.roles) {
            if (permissionsArr.includes(role.toLowerCase())) return true;
        }
        return false;

    }
    return {
        user,
        getToken,
        canAccessApplication,
        hasPermission
    }
}
// 