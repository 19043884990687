// ** Store Imports
// import { handleNavbarType } from "@store/layout";
// import { useDispatch, useSelector } from "react-redux";

const handleNavbarType=(b)=>{
}
export const useNavbarType = (store) => {
  // ** Hooks
  // const dispatch = useDispatch(store);
  // const store = useSelector((state) => state.layout);

  const setNavbarType = (type) => {
    handleNavbarType(type);
    // dispatch(handleNavbarType(type));
  };

  return { navbarType: store.navbarType, setNavbarType };
};
