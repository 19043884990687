
import Layout from "@layouts/HorizontalLayout";
import {navigation} from "../utility/navigations";
// import '../index.scss'
// import '../assets/scss/style.scss'
const HorizontalLayout = ({navigation=[],...props}) => {
  const {reactDom}=props
  const {Outlet}=reactDom
  return (
    <Layout menuData={navigation} {...props}>
      <Outlet />
    </Layout>
  );
};

export default HorizontalLayout;
